let SnapProjectName = "TuneScope.xml"

export default class SnapURLSynchronizer {
    snapiFrame;

    constructor(snapiFrame) {
        this.snapiFrame = snapiFrame;
    }

    /**
     * Updates the TuneScope URL in the form of https://tunescope.org/index.html#project:Username={username}&ProjectName={projectName}
     * @param snapURL
     */
    updateTuneScopeURL = (projectName, username) => {
        const paramsString = `Username=${encodeURIComponent(username)}&ProjectName=${encodeURIComponent(projectName)}`
        const newPath = "/index.html#project:" + paramsString;

        window.history.pushState({}, null, newPath);
    }

    clearTuneScopeURL = () => {
        window.history.pushState({}, null, "/");
    }

    /**
     * Updates the Snap URL in the form of https://snap.berkeley.edu/snap/snap.html#present:Username=glenbull&ProjectName=Stand%20By%20Me%20(Ver%203.9)
     * @param tuneScopeURL
     */
    updateSnapURL = (tuneScopeURL) => {
        console.log("Updating Snap URL with TuneScope URL " + tuneScopeURL);
        const paramsString = this.extractParametersStringFromURL(tuneScopeURL);
        if (paramsString) {
            const newURL = "https://snap.berkeley.edu/snap/snap.html#present:" + paramsString; // "./Snap/snap.html#present:" + paramsString;
            console.log("Crafted Snap URL: " + newURL);
            setTimeout(() => {
                this.snapiFrame.loadSnapProject(newURL);
            }, 1000)
        }
    }

    /**
     *
     * @param urlString
     */
    extractParametersStringFromURL = (urlString) => {
        const splitByHTML = urlString.split(".html");
        console.log(splitByHTML)
        if (splitByHTML && splitByHTML.length > 1) {
            const pageAndArgs = splitByHTML[1];
            console.log(pageAndArgs)
            if (pageAndArgs) {
                const splitByColon = pageAndArgs.split(":", 2);
                console.log(splitByColon);
                if (splitByColon && splitByColon.length > 1) {
                    return splitByColon[1];
                }
            }
        }
        /*
        const lastSlashIndex = urlString.lastIndexOf("/");
        const pageAndArgs = lastSlashIndex !== -1 && urlString.substring(lastSlashIndex);

         */

        return null;
    }

    /**
     *
     * @param url
     * @returns {Promise<unknown>} - {username, projectName}
     */
    parseTuneScopeURL = (url) => {
        return new Promise((resolve, reject) => {
            const paramsString = this.extractParametersStringFromURL(url);
            if (paramsString === null) {reject("Could not parse TuneScope URL"); return;}
            const response = {};

            // get username
            const splitByUsername = paramsString.split("Username=");
            const usernameSplitByAmpersand = splitByUsername[1].split("&");
            response.username = decodeURIComponent(usernameSplitByAmpersand[0]);

            // get projectName
            const splitByProjectName = paramsString.split("ProjectName=");
            const projectNameSplitByAmpersand = splitByProjectName[1].split("&");
            response.projectName = decodeURIComponent(projectNameSplitByAmpersand[0]);

            resolve(response);
        })
    }

    getCurrentTuneScopeDomain = () => {
        const fullURL = window.location.href;
        const urlSplitBySlash = fullURL.split("#", 1);
        if (urlSplitBySlash && urlSplitBySlash.length > 0) {
            return urlSplitBySlash[0];
        }
        return null;
    }

    onSnapProjectLoaded = (projectName, username) => {
        this.updateTuneScopeURL(projectName, username);
    }

    onNewProject = () => {
        this.clearTuneScopeURL();
    }

    onTuneScopeURLChanged = (tuneScopeURL) => {
        this.updateSnapURL(tuneScopeURL);
    }

}