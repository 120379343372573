/**
 * Auxillary file for utility functions
 */

// repeats an array n times
// similar to [1,2,3] * 2 = [1,2,3,1,2,3] in Python
export const multiplyArray = (arr, length) =>
  Array.from({ length }, () => arr).flat()


/*

Queue.js

A function to represent a queue

Created by Kate Morley - http://code.iamkate.com/ - and released under the terms
of the CC0 1.0 Universal legal code:

http://creativecommons.org/publicdomain/zero/1.0/legalcode

*/

/**
 * Creates a new queue. A queue is a first-in-first-out (FIFO) data structure -
 * items are added to the end of the queue and removed from the front.
 */
export function Queue() {

  // initialise the queue and offset
  var queue = [];
  var offset = 0;

  // Returns the length of the queue.
  this.getLength = function () {
    return (queue.length - offset);
  }

  // Returns true if the queue is empty, and false otherwise.
  this.isEmpty = function () {
    return (queue.length === 0);
  }

  /* Enqueues the specified item. The parameter is:
   *
   * item - the item to enqueue
   */
  this.enqueue = function (item) {
    queue.push(item);
  }

  /* Dequeues an item and returns it. If the queue is empty, the value
   * 'undefined' is returned.
   */
  this.dequeue = function () {

    // if the queue is empty, return immediately
    if (queue.length === 0) return undefined;

    // store the item at the front of the queue
    var item = queue[offset];

    // increment the offset and remove the free space if necessary
    if (++offset * 2 >= queue.length) {
      queue = queue.slice(offset);
      offset = 0;
    }

    // return the dequeued item
    return item;

  }

  /* Returns the item at the front of the queue (without dequeuing it). If the
   * queue is empty then undefined is returned.
   */
  this.peek = function () {
    return (queue.length > 0 ? queue[offset] : undefined);
  }

}

/**
 * Converts all elements in a nested array (2D, 3D, etc) to lowercase
 * @param {*} arr 
 */
export function toLowerCaseRecursive(array) {
  //check for arrays and recurse
  if (Array.isArray(array)) {
    for (var i = 0; i < array.length; i++) {
      array[i] = toLowerCaseRecursive(array[i]);
    }
    return array;
  }
  //check for string vs non-strings
  if (typeof array === "string") {
    if (!hasNumber(array)) { //contains no numbers, so it isn't a note value
      return array.toLowerCase();
    } else {  //case with note values, which contain numbers
      //capitalize the first character in the string
      return array[0].toUpperCase() + array.slice(1);
    }
  } else {
    return array;
  }
}

function hasNumber(myString) {
  return /\d/.test(myString);
}

export function deep_copy(array) {
  return JSON.parse(JSON.stringify(array));
}
