export default class LocalSnapLogger {
    recording = false;
    currentLog = [];

    constructor() {

    }

    resetRecording = () => {
        this.currentLog = [];
    }

    startRecording = () => {
        this.recording = true;
    }

    logToText = (log) => {
        var text = "";
        for (var i = 0; i < log.length; i++) {
            const currEntry = log[i];
            var currEntryText = "";
            currEntryText += currEntry.time + ": "
            currEntryText += currEntry.data;

            text += currEntryText;
            text += "\n";
        }
        return text;
    }

    saveRecordingToJSON = () => {
        console.log(this.currentLog);
        const text = this.logToText(this.currentLog);
        const a = document.createElement("a");
        const file = new Blob([text], {type: 'text/plain'});
        a.href = URL.createObjectURL(file);
        a.download = "SnapRecording.txt";
        a.click();
    }

    log = (data) => {
        if (!this.recording) return;
        if (typeof(data) === "string" && data !== "undefined") {
            this.currentLog.push({
                time: new Date().getTime(),
                data: data
            })
        }
    }
}