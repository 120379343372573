export var timeSignatureToBeatsPerMeasure = {
    "4/4": [4,1], // 4 beats per measure, Quarter note gets the beat
    "3/4": [3,1],
    "5/4": [5,1],
    "7/4": [7,1],
    "6/8": [6,0.5], // 6 beats per measure, Eighth note gets the beat
    "9/8": [9,0.5],
    "12/8": [12,0.5]
}

export const baseTempo = 60;

// converts note lengths (quarter, half, whole)
// to corresponding time value (1, 2, 4)
export var noteLengthToTimeValue = {
    "dotted whole": 6,
    "whole": 4,
    "dotted half": 3,
    "half": 2,
    "dotted quarter": 1.5,
    "quarter": 1,
    "dotted eighth": 0.75,
    "eighth": 0.5,
    "dotted sixteenth": 0.375,
    "sixteenth": 0.25,
    "dotted thirtysecond": 0.1875,
    "thirtysecond": 0.125,
    "whole triplet": 2.667,
    "half triplet": 1.333,
    "quarter triplet": 0.667,
    "eighth triplet": 0.333,
    "sixteenth triplet": 0.167,
    "thirtysecond triplet": 0.0417
}