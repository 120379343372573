import GuideFrame from "./GuideFrame"

export default class GuideFrameFactory {
    constructor() {
        this.guideFrames = {};
    }

    getGuideFrame = (id) => {
        if (!id) {
            throw new Error("No ID provided to getGuideFrame");
        }
        let guideFrame = this.guideFrames[id];
        if (!guideFrame) {
            guideFrame = new GuideFrame(document.getElementById("mainBody"), id);
            this.guideFrames[id] = guideFrame;
        }
        return guideFrame;
    }
}