// import {parseScore, serializeScore} from '/musicxml-interfaces';

/**
 * MusicXMLAgent - this class can be used to parse (decode) MusicXML files into a TuneScope-understandable format
 * and to serialize (encode) TuneScope files into MusicXML
 */
export default class MusicXMLAgent {
    xmlParser;
    musicXMLToTuneScopeCSVVocab;
    chords;

    constructor() {
        this.xmlParser = new DOMParser();

        this.musicXMLToTuneScopeCSVVocab = {
            rest: "Rest",
            whole: "Whole",
            half: "Half",
            quarter: "Quarter",
            eighth: "Eighth",
            "16th": "Sixteenth",
            "32nd": "Thirty-Second",
            "64th": "Sixty-Forth"
        }
    }

    findChord = (chordString) => {

    }

    downloadCSV = (csvArray) => {
        // create properly formatted csv string
        let csvContent = "data:text/csv;charset=utf-8,"
            + csvArray.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);

        // name the file
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "SongOutput.csv");
        document.body.appendChild(link); // Required for FF

        // download
        link.click();
    }

    // building chords, look into ties

    /**
     * Converts a MusicXML string to a TuneScope string
     * @param musicXMLString
     */
    convertMusicXMLStringToTuneScopeCSV = (musicXMLString) => {
        let xmlDoc = this.xmlParser.parseFromString(musicXMLString, "text/xml");
        let tuneScopeCSV = [];

        // declare header data fields
        let title;
        let numScoreParts;
        let columnHeaderArray;

        // get header data
        let works = xmlDoc.getElementsByTagName("work");
        let work = works[0];
        if (work) {
            let workTitles = work.getElementsByTagName("work-title");
            let workTitle = workTitles[0];
            if (workTitle) {
                title = workTitle.childNodes[0].nodeValue
            }
        }

        // get column header data
        let partLists = xmlDoc.getElementsByTagName("part-list");
        if (partLists) {
            let partList = partLists[0];
            if (partList) {
                let scoreParts = partList.getElementsByTagName("score-part");
                if (scoreParts) {
                    numScoreParts = scoreParts.length;
                    columnHeaderArray = [];
                    for (var i = 0; i < scoreParts.length; i++) {
                        let currScorePart = scoreParts[i];
                        columnHeaderArray.push("Track " + (i + 1) + " Instrument:");
                        let scoreInstruments = currScorePart.getElementsByTagName("score-instrument");
                        if (scoreInstruments) {
                            let scoreInstrument = scoreInstruments[0];
                            let instrumentNames = scoreInstrument.getElementsByTagName("instrument-name");
                            if (instrumentNames) {
                                let instrumentName = instrumentNames[0];
                                if (instrumentName) {
                                    columnHeaderArray.push(instrumentName.childNodes[0].nodeValue);
                                }
                            }
                        }
                    }
                }
            }
        }

        // build column header
        tuneScopeCSV.push(["Note", "Duration", "Chord", "Duration", "Lyrics"]);

        // iterate over each part
        let startRowIndexForParts = tuneScopeCSV.length;
        let parts = xmlDoc.getElementsByTagName("part");
        for (var i = 0; i < parts.length; i++) {
            let currentPart = parts[i];
            let measures = currentPart.getElementsByTagName("measure");
            let currRowIndex = startRowIndexForParts;

            // iterate over each measure in this part
            for (var j = 0; j < measures.length; j++) {
                let currentMeasure = measures[j];
                let notes = currentMeasure.getElementsByTagName("note");

                // iterate over each note in this measure
                for (var k = 0; k < notes.length; k++) {
                    let currentNote = notes[k];

                    // build noteValue
                    var noteValue = "";
                    let pitch = currentNote.getElementsByTagName("pitch")[0];
                    if (pitch) { // actual note
                        let step = pitch.getElementsByTagName("step")[0].childNodes[0].nodeValue;
                        noteValue += step;
                        let alters = pitch.getElementsByTagName("alter");
                        if (alters && alters.length > 0) {
                            let alter = alters[0].childNodes[0].nodeValue;
                            switch (alter) {
                                case "-1":
                                    noteValue += "b";
                                    break;
                                case "1":
                                    noteValue += "#";
                                    break;
                            }
                        }
                        let octave = pitch.getElementsByTagName("octave")[0].childNodes[0].nodeValue;
                        noteValue += octave;
                    } else {
                        let rest = currentNote.getElementsByTagName("rest");
                        if (rest && rest[0]) {
                            noteValue = "rest"
                        } // TODO: else?
                    }

                    // build lengthValue
                    var lengthValue = "";
                    let noteType = currentNote.getElementsByTagName("type")[0].childNodes[0].nodeValue;
                    lengthValue += noteType;

                    let currRow = tuneScopeCSV[currRowIndex];
                    if (!currRow) {
                        currRow = [];
                        tuneScopeCSV[currRowIndex] = currRow;
                    }

                    // push note
                    currRow.push(this.musicXMLToTuneScopeCSVVocab[noteValue] || noteValue);
                    // push duration
                    currRow.push(this.musicXMLToTuneScopeCSVVocab[lengthValue] || lengthValue);

                    currRowIndex++;
                }
            }
        }

        console.log("Conversion Result: ", tuneScopeCSV);

        return tuneScopeCSV;
    }

    /**
     * Converts a TuneScope string to a MusicXML string
     * @param tuneScopeString
     */
    convertTuneScopeStringToMusicXMLString = (tuneScopeString) => {
        // TODO: stub

        return "temp XML";
    }
	
	
	
	/** @brief Helper function: Converts an abc note length into Tunescope note length
	 *
	 * @param lengthInString
	 * @param modString
	 * @note example: lengthInString = "1/8", modString = "4", return "Half"
	 * @author David Xue
	 * @date 10/15/2020
	 */
	convertABCDuration = (lengthInString, modString) => {
		
		//console.log ("Length is "+lengthInString+ " mod is " + modString);
		var len;
		switch(lengthInString)
		{
			case "1":
				len = 1;
				break;
			case "1/2":
				len = 1/2;
				break;
			case "1/4":
				len = 1/4;
				break;
			case "1/8":
				len = 1/8;
				break;
			case "1/16":
				len = 1/16;
				break;
          default:
				len = parseInt(lengthInString[0])/parseInt(lengthInString[2]);
              break;
		}
		switch(modString)
		{
			case "1":
				break;
			case "2":
			case "3":
			case "4":
			case "6":
			case "8":
			case "16":
				len *= parseInt(modString);
				break;
			case "/2":
			case "/3":
			case "/4":
			case "/6":
			case "/8":
				len /= parseInt(modString[1]);
				break;
			case "/16":
				len /= parseInt(modString.slice(1));
				break;
			case "3/2":
				len = len * 3 /2;
				break;
			default:
				len /= parseInt(modString[1]);
				break;
		}
		switch(len)
		{
			case (1):
				return "Whole";
				break;
			case (1/2):
				return "Half";
				break;
			case (1/4):
				return "Quarter";
				break;
			case (1/8):
				return "Eighth";
				break;
			case (1/16):
				return "Sixteenth";
				break;
			case (1/32):
				return "Thirty-second";
				break;
			case (3/4):
				return "Dotted Half";
				break;
			case (3/8):
				return "Dotted Quarter";
				break;
			case (3/16):
				return "Dotted Eighth";
				break;
			case (3/32):
				return "Dotted Sixteenth";
				break;
			default:
				console.log("Return invalid: "+len);
				return "Invalid parameter";
		}
				
	}
	
	
	
	
	/** @brief Converts an abc String into a TuneScope string
	 *
	 * @param abcXMLString
	 * @note Assuming the parameter passed in is a string, and output is an array for CSV format
	 * @author David Xue
	 * @date 10/15/2020
	 */
    convertABCStringToTuneScopeCSV = (abcString) => {
		let tuneScopeCSV = [];
		var lines = abcString.trim().split("\n");	
		var index =1, title = "Invalid ABC file", meter = "m", length = "len", key = "key", start = 0;
		var sharpNotes = [];
		var flatNotes = [];
		var tbSize = 0;
		var compound = false;
		tuneScopeCSV.push(["Note", "Duration"]);
		
		//find the start point of the notes/ending point of the header fields
		for (var x = 0; x < lines.length; x++)
		{
			if(lines[x].includes("K:"))
			{
				start = x+1;
				key = lines[x].split(":")[1];
				key = key.replace(/ /g,'');
				key = key.trim();
				break;
			}
			
		}
		
		//Array for key notes to sharp according to Key signature
		switch (key)
		{
			case "C":
			case "Cmaj":
			case "Amin":
			case "Am":
			case "Bmin":
			case "Bm":
			case "Fmin":
			case "Fm":
			case "F#min":
			case "F#m":
			case "Cmin":
			case "C#min":
			case "C#m":
				sharpNotes = [];
				break;
			case "C#":
			case "C#maj":
				sharpNotes.push("B");
			case "F#maj":
			case "F#":
				sharpNotes.push("E");
			case "B":
			case "Bmaj":
				sharpNotes.push("A");
			case "E":
			case "Bmaj":
				sharpNotes.push("D");
			case "A":
			case "Amaj":
				sharpNotes.push ("G");
			case "Dmaj":
			case "D":
				sharpNotes.push("C");
			case "Gmaj":
			case "G":
				sharpNotes.push("F");
				break;
			//add G#min D#min A#min
		}
		
		switch (key)
		{
			case "Cb":
			case "Cbmaj":
				flatNotes.push("F");
			case "Gb":
			case "Gbmaj":
				flatNotes.push("C");
			case "Db":
			case "Dbmaj":
				flatNotes.push("G");
			case "Ab":
			case "Abmaj":
				flatNotes.push("D");
			case "Eb":
			case "Ebmaj":
				flatNotes.push ("A");
			case "Bb":
			case "Bbmaj":
				flatNotes.push("E");
			case "Fmaj":
			case "F":
				flatNotes.push("B");
				break;
		}
		
		//Capture the header fields  (index, title, meter, length, etc)
		for (var i = 0; i < start; i++)
		{
			if(lines[i].includes("X:"))
			{
				index = (lines[i].trim().split(":"))[1];
			}
			else if (lines[i].includes("M:"))
			{
				meter = (lines[i].trim().split(":"))[1];
				meter = meter.replace(/ /g,'');
			}
			else if (lines[i].includes("L:"))
			{
				length = (lines[i].trim().split(":"))[1];
				length = length.replace(/ /g,'');
				
			}
			else if (lines[i].includes("T:"))
			{
				title = (lines[i].trim().split(":"))[1];
				title = title.replace(/ /g,'');
			}
		}
		//sets default note length if no L field found
		if(length == "len")
			length = "1/8";
		

		var temp;//array of string blocks in each line
		var musicNotes = ""; //string of all music notes and symbols
		
		//Merge all notes and symbols into one string, ignoring beams
		for (var y = start; y <lines.length; y++)
		{
			temp = lines[y].trim().split(/\s+/);
			for( var z = 0; z < temp.length; z++)
			{
				musicNotes += temp[z];
			}
		}
		
		var notes = "", duration = "", atEnd = false, mod = "", chord = "", sharp = false, flat = false;
		for (var p=0; p < musicNotes.length; p++)
		{
			if(musicNotes.length == (p+1))
				atEnd = true;
			//console.log("Current symbol is " + musicNotes.charAt(p));
			notes = "";
			duration = "";
			mod = "";
			chord = "";
			if(compound && tuneScopeCSV.length > tbSize)
			{
				compound = false;
				while(musicNotes.charAt(p)!= ']'){
					p++;
				};
			}
			switch(musicNotes.charAt(p))
			{
				case 'C':
				case 'D':
				case 'E':
				case 'F':
				case 'G':
				case 'A':
				case 'B':
					if(!atEnd)
					{
						
						//checks if next character is a note or letter or , 
						if(musicNotes.charAt(p+1) == ',')
						{
							
							notes += musicNotes.charAt(p);
							if(sharp == true)
							{
								notes +='#';
								sharp = false;
							}else if(flat == true)
							{
								notes +='b';
								flat = false;
							}
							notes += '3';
							if(musicNotes.charAt(p+2).match(/[0-9]/i) || musicNotes.charAt(p+2) == '/' )
							{
								p++;
							}
						}else
						{
							notes += musicNotes.charAt(p);
							if(sharp == true)
							{
								notes +='#';
								sharp = false;
							}else if(flat == true)
							{
								notes +='b';
								flat = false;
							}
							notes += '4';
						}
					}
					if(!atEnd)
					{
						if(musicNotes.charAt(p+1).match(/[0-9]/i))
						{
							mod = musicNotes.charAt(p+1);
							duration = this.convertABCDuration(length, mod);
						}else if(musicNotes.charAt(p+1)== "/" )
						{
							if(musicNotes.charAt(p+2).match(/[0-9]/i))
							{//if after / has num then duration change, else / is /2
								if(p+3< musicNotes.length)
								{
									//check if it is /16 or /32
									if(musicNotes.charAt(p+3).match(/[0-9]/i))
									{
										mod = musicNotes.charAt(p+1)+musicNotes.charAt(p+2)+musicNotes.charAt(p+3);
										duration = this.convertABCDuration(length, mod);
									}
									else
									{
										mod= musicNotes.charAt(p+1)+musicNotes.charAt(p+2);
										duration = this.convertABCDuration(length, mod);
									}
								}else{
									mod = musicNotes.charAt(p+1)+musicNotes.charAt(p+2);
									duration = this.convertABCDuration(length, mod);
								}		
							}else{
									// default/2
									duration = this.convertABCDuration(length, "/2");
							}
						}else if(musicNotes.charAt(p+1)== ">")
						{ 
						//if A>B, then A is 1.5/length and B is 0.5/length
						
							duration = this.convertABCDuration(length, "3/2");
						}else if(musicNotes.charAt(p+1)== "<")
						{ 
						//if A>B, then A is 1.5/length and B is 0.5/length
						
							duration = this.convertABCDuration(length, "/2");
						}
						else if(musicNotes.charAt(p-1)== ">")
						{
							duration = this.convertABCDuration(length, "/2");			
						}else if(musicNotes.charAt(p-1)== "<")
						{
							duration = this.convertABCDuration(length, "3/2");			
						}					
						//if note by itself
						else{
							duration = this.convertABCDuration(length, "1");
						}
					}
					
					break;
				case 'c':
				case 'd':
				case 'e':
				case 'f':
				case 'g':
				case 'a':
				case 'b':
					if(!atEnd)
					{
						//console.log("Checking" + musicNotes.charAt(p+1) );
						//checks if next character is a note or letter or , or '
						if(musicNotes.charAt(p+1) == '\'')
						{
							notes += musicNotes.charAt(p).toUpperCase();
							if(sharp == true)
							{
								notes +='#';
								sharp = false;
							}else if(flat == true)
							{
								notes +='b';
								flat = false;
							}
							
							notes += '6';
							if(musicNotes.charAt(p+2).match(/[0-9]/i) || musicNotes.charAt(p+2) == '/' )
							{
								p++;
							}
						}else
						{
							notes += musicNotes.charAt(p).toUpperCase();
							if(sharp == true)
							{
								notes +='#';
								sharp = false;
							}else if(flat == true)
							{
								notes +='b';
								flat = false;
							}
							notes += '5';
						}
					}
					if(!atEnd)
					{
						if(musicNotes.charAt(p+1).match(/[0-9]/i))
						{
							mod += musicNotes.charAt(p+1);
							duration = this.convertABCDuration(length, mod);
						}else if(musicNotes.charAt(p+1)== "/")
						{
							if(musicNotes.charAt(p+2).match(/[0-9]/i))
							{//if after / has num then duration change, else / is /2
								if(p+3< musicNotes.length)
								{
									//check if it is /16 or /32
									if(musicNotes.charAt(p+3).match(/[0-9]/i))
									{
										mod += (musicNotes.charAt(p+1)+musicNotes.charAt(p+2)+musicNotes.charAt(p+3));
										duration = this.convertABCDuration(length, mod);
									}
									else
									{
										mod += (musicNotes.charAt(p+1)+musicNotes.charAt(p+2));
										duration = this.convertABCDuration(length, mod);
									}
								}else{
									mod += (musicNotes.charAt(p+1)+musicNotes.charAt(p+2));
									duration = this.convertABCDuration(length, mod);
								}
							}else{
								//default /2
								duration = this.convertABCDuration(length, "1/2");
							}								
						}else if(musicNotes.charAt(p+1)== ">")
						{ 
						//if A>B, then A is 1.5/length and B is 0.5/length
						
							duration = this.convertABCDuration(length, "3/2");
						}else if(musicNotes.charAt(p+1)== "<")
						{ 
						//if A>B, then A is 1.5/length and B is 0.5/length
						
							duration = this.convertABCDuration(length, "/2");
						}
						else if(musicNotes.charAt(p-1)== ">")
						{
							duration = this.convertABCDuration(length, "/2");			
						}else if(musicNotes.charAt(p-1)== "<")
						{
							duration = this.convertABCDuration(length, "3/2");			
						}
						//if note by itself
						else{
							duration = this.convertABCDuration(length, "1");
						}
					}
					break;
				case '"':
					if(!atEnd)
					{
						chord += musicNotes.charAt(p+1);
						chord += " Chord";
						p += 2;
					}
					break;
				case "^":
					sharp = true;
					break;
				case "_":
					flat = true;
					break;
				case 'z':
					notes = "Rest";
					if(!atEnd)
					{
						if(musicNotes.charAt(p+1).match(/[0-9]/i))
						{
							mod = musicNotes.charAt(p+1);
							duration = this.convertABCDuration(length, mod);
						}else if(musicNotes.charAt(p+1)== "/" )
						{
							if(p+3< musicNotes.length)
							{
								//check if it is /16 or /32
								if(musicNotes.charAt(p+3).match(/[0-9]/i))
								{
									mod = musicNotes.charAt(p+1)+musicNotes.charAt(p+2)+musicNotes.charAt(p+3)
									duration = this.convertABCDuration(length, mod);
								}
								else
								{
									mod = musicNotes.charAt(p+1)+musicNotes.charAt(p+2);
									duration = this.convertABCDuration(length, mod);
								}
							}else{
								mod = musicNotes.charAt(p+1)+musicNotes.charAt(p+2);
								duration = this.convertABCDuration(length, mod);
							}							
						}
						//if note by itself
						else{
							duration = this.convertABCDuration(length, "1");
						}
					}
					break;
				case '[':
					tbSize = tuneScopeCSV.length;
					compound = true;
					break;
					
			}
			//console.log("The note is: "+ notes);
			for(var r = 0; r < sharpNotes.length; r++)
			{
				
				if(notes.includes(sharpNotes[r]) && notes.length <=2)
				{
					notes = [notes.slice(0, 1), "#", notes.slice(1)].join('');
				}
			}
			for(var r = 0; r < flatNotes.length; r++)
			{
				if(notes.includes(flatNotes[r]) && notes.length <=2)
				{
					notes = [notes.slice(0, 1), "b", notes.slice(1)].join('');
				}
			}
			
			if(notes != "" && duration != "")
				tuneScopeCSV.push([notes,duration]);
		}
		
		
		
		console.table(tuneScopeCSV);
		return tuneScopeCSV;
	}
	
}