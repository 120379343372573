

export default class BlockBuilder {
    snap;
    initialXPosition = 250;
    initialYPosition = 150;
    standardBlockHeight = 40;

    currXPosition;
    currYPosition;

    instructions = []; // TODO: use a queue instead
    currentVariables = [];
    currInstructionIndex = 0;
    blocksCreatedPreviouslyInCurrentScript = false;

    shouldDeleteVariables = false;
    shouldReset = false;
    running = false;

    constructor(snap) {
        this.snap = snap;
        this.currXPosition = this.initialXPosition;
        this.currYPosition = this.initialYPosition;
    }

    parseScriptIntoInstructions = (script) => {
        const lines = script.split("\n");
        return lines;
    }

    extractArgsFromInstruction = (instruction) => {
        const openingBracketPosition = instruction.indexOf("[");
        if (openingBracketPosition === -1) return [];

        const closingBracketPosition = instruction.indexOf("]", openingBracketPosition);
        if (closingBracketPosition === -1) return [];

        const argsSubstring = instruction.substring(openingBracketPosition + 1, closingBracketPosition);
        const args = argsSubstring.split(" ");

        return args;
    }

    extractBlockNameFromInstruction = (instruction) => {
        const spacePosition = instruction.indexOf(" ");
        const bracketPosition = instruction.indexOf("[");
        if (spacePosition === -1 && bracketPosition === -1) {
            return instruction;
        } else if (bracketPosition === -1) {
            return instruction.substring(0, spacePosition);
        } else if (spacePosition === -1) {
            return instruction.substring(0, bracketPosition);
        } else {
            return instruction.substring(0, Math.max(spacePosition, bracketPosition));
        }
    }

    extractVarNameFromInstruction = (instruction) => {
        const equalSignIndex = instruction.indexOf("=");
        if (equalSignIndex === -1) return null;
        const openBracketIndex = instruction.indexOf("[");
        if (openBracketIndex === -1 || openBracketIndex < equalSignIndex) return null;
        const varName = instruction.substring(0, equalSignIndex).trim();
        return varName;
    }

    runScript = async (script) => {
        if (this.running) {
            throw new Error("Already running a script");
            return;
        }
        this.instructions = this.parseScriptIntoInstructions(script);
        await this.resume();
    }

    runAllInstructions = async (startInstruction) => {
        for (var i = startInstruction; i < this.instructions.length; i++) {
            if (!this.running) return;
            if (this.shouldReset) {
                this.reset();
                return;
            }

            let currInstruction = this.instructions[i];
            if (currInstruction.length <= 1) continue;

            await this.wait(500);

            if (currInstruction === "") continue; // TODO: check for whitespace

            const varName = this.extractVarNameFromInstruction(currInstruction);
            if (varName !== null) {
                currInstruction = currInstruction.substring(currInstruction.indexOf("=") + 1).trim();
            }
            const blockName = this.extractBlockNameFromInstruction(currInstruction);

            // get short block name
            let shortBlockName = blockName;
            const indexOfSpace = blockName.indexOf(" ");
            if (indexOfSpace !== -1) {
                shortBlockName = blockName.substring(0, indexOfSpace);
            }
            const x = this.currXPosition;
            const y = this.currYPosition;

            const args = this.extractArgsFromInstruction(currInstruction);
            try {
                switch (shortBlockName) {
                    case "SetInput": {
                        if (args.length < 1) break;
                        this.snap.setInput({
                            varName: args[0],
                            args: args.slice(1, args.length)
                        });
                        break;
                    }
                    case "Snap": {
                        if (args.length < 2) break;
                        this.snap.snapBlocks({
                            block1VarName: args[0],
                            block2VarName: args[1]
                        });
                        break;
                    }
                    case "Insert": {
                        if (args.length < 2) break;
                        this.snap.insertBlockIntoBlock({
                            childVarName: args[0],
                            parentVarName: args[1]
                        });
                        break;
                    }
                    case "Delete": {
                        if (args.length < 1) break;
                        this.snap.deleteBlock({
                            varName: args[0]
                        });
                        break;
                    }
                    case "Exec": {
                        if (args.length < 1) break;
                        this.snap.executeBlock({
                            varName: args[0]
                        });
                        break;
                    }
                    case "ResetBounds": {
                        this.currXPosition = this.initialXPosition;
                        this.currYPosition = this.initialYPosition;
                        break;
                    }
                    case "Space": {
                        if (args.length < 1) args.push(1);

                        this.currYPosition += args[0] * this.standardBlockHeight;
                        break;
                    }
                    case "Wait": {
                        if (args.length < 1) break;
                        const waitTime = Number.parseFloat(args[0]) * 1000;
                        await this.wait(waitTime);
                        break;
                    }
                    default: {
                        let snapToNearby = true;
                        if (!this.blocksCreatedPreviouslyInCurrentScript) {
                            this.blocksCreatedPreviouslyInCurrentScript = true;
                            snapToNearby = false;
                        }
                        const blockGenerated = await this.snap.newBlock({
                            varName: varName,
                            name: blockName,
                            x: x,
                            y: y,
                            args: args,
                            snapToNearby: snapToNearby
                        });
                        console.log("blockGenerated", blockGenerated);
                        if (!blockGenerated) break;
                        if (varName) {
                            this.currentVariables.push(varName);
                        }
                        // Update currYPosition using bottom of block
                        const blockSizeY = blockGenerated.bounds.corner.y - blockGenerated.bounds.origin.y;
                        this.currYPosition += blockSizeY;
                        console.log(this.currYPosition);
                        break;
                    }
                }
            } catch (err) {
                this.onError(err);
                throw err;
            }

            this.currInstructionIndex = i + 1;
        }
        this.onAllInstructionsFinished();
    }

    onError = () => {
        this.reset();
    }

    onAllInstructionsFinished = () => {
        this.reset();
    }

    deleteAllVariables = () => {
        console.log("DELETING ALL VARIABLES")
        this.currentVariables.forEach((varName) => {
            try {
                this.snap.deleteBlock({
                    varName: varName
                });
            } catch(err) {
                console.log("Error deleting block: ", err);
            }
        })
    }

    reset = () => {
        // prepare bounds for next execution
        //this.currXPosition += 120;
        this.currYPosition = this.initialYPosition

        if (this.shouldDeleteVariables) { // delete blocks
            this.deleteAllVariables();
            this.shouldDeleteVariables = false;
        }
        this.currentVariables = [];

        // reset other fields
        this.currInstructionIndex = 0;
        this.instructions = [];

        this.blocksCreatedPreviouslyInCurrentScript = false;

        this.shouldReset = false;

        this.running = false;
    }

    wait = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }

    pause = () => {
        if (!this.running) {
            throw new Error("Not running any script")
        }
        this.running = false;
    }

    resume = async () => {
        if (this.running) {
            throw new Error("Already running a script")
        }
        this.running = true;
        await this.runAllInstructions(this.currInstructionIndex);
    }

    stop = () => {
        if (!this.running) {
            this.deleteAllVariables();
            throw new Error("Not running any script")
        }
        this.shouldDeleteVariables = true;
        this.shouldReset = true;
    }
}