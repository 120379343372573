export default class AbcAIAgent {
    constructor() {
        this.serverURL = "http://sodium-primer-290913.ue.r.appspot.com";
        this.path = "/result";
        this.port = 80;
    }

    static getPrediction = (abcString, number) => {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({
                notation: abcString,
                number: number
            });

            const formData = new FormData();
            formData.append("json", data);

            fetch("https://sodium-primer-290913.ue.r.appspot.com/result", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Content-Length': formData.length
                },
                body: data
            }).then((response) => {
                const text = response.text();
                // TODO: parse text for result
                resolve(text);
            }).catch((err) => {
                reject(err);
            })
        })
    }
}