
export default class SongSearch {
	constructor() {}

	doCORSRequest = (options, printResult) => {
		return new Promise((resolve, reject) => {
			//var cors_api_url = 'https://cors-anywhere.herokuapp.com/';
			
			//David's free heroku deployment, temporary use:
			var cors_api_url = 'https://tunescope.herokuapp.com/';
			
			var x = new XMLHttpRequest();
			x.open(options.method, cors_api_url + options.url);
			x.onload = x.onerror = function () {
				printResult(
					options.method + ' ' + options.url + '\n' +
					x.status + ' ' + x.statusText + '\n\n' +
					(x.responseText || ''));
			};

			if (/^POST/i.test(options.method)) {
				x.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			}
			x.send(options.data);
			resolve();
		})

	}

	/** @brief Using the searchString and search for the corresponding song in abc notation on abcnotation.com
	 *
	 * @param searchString
	 * @author David Xue
	 * @date 11/15/2020
	 */
	getABCSearchResult = (searchString) => {
		return new Promise((resolve, reject) => {
			let abcString = "X:1\nT:Mary had a little lamb\nM:4/4\nL:1/8\nK:C\n|:\"C\"edcd eee2|\"G\"ddd2 \"C\"egg2|edcd eeec|\"G\"dded \"C\"c4|";
			var keywords = searchString.split(' ');
			var urlField = "http://abcnotation.com/searchTunes?q=";
			var dataField = "";
			var outputField = "";

			for (var i = 0; i < keywords.length; i++) {
				urlField += keywords[i];
				if (i != keywords.length - 1)
					urlField += '+';
			}
			urlField += "&f=c&o=a&s=0";
			console.log("url: " + urlField);

			//Scrap search results, find the first result on page
			const doCORSRequest = this.doCORSRequest;
			
			this.doCORSRequest({
				method: this.id === 'post' ? 'POST' : 'GET',
				url: urlField,
				data: dataField
			}, function printResult(result) {
				outputField = result;

				//console.log("outputfield is empty again wtf" + outputField);

				var start = outputField.indexOf("<a href=\"/tunePage?");
				start += 10;
				console.log("start" + start);

				var end = start;
				for (var i = start; i < outputField.length; i++) {
					if (outputField[i] == '\"' && outputField[i + 1] == '>') {
						end = i;
						break;
					}
				}
				console.log("end" + end);

				//find the url of the first result
				urlField = "https://abcnotation.com/" + outputField.substring(start, end);
				console.log("result: " + urlField);

				//TODO: Scrap HTML Data of the first result song

				
				doCORSRequest({
					method: 'GET',
					url: urlField,
					data: dataField
				}, function printResult(result) {
					outputField = result;
					
					
					
					start = outputField.indexOf("readonly\">");
					start += 10;
					console.log("start" + start);

					end = start;
					for (var i = start; i < outputField.length; i++) {
						if (outputField[i] == '<' && outputField[i + 1] == '/' && outputField[i + 2] == 't') {
							end = i;
							break;
						}
					}
					console.log("end" + end);
					abcString = outputField.substring(start, end);
					resolve(abcString);

				});
				
			});

		})
	}
}