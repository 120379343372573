let instrumentData = {
    "accordion": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0230_Aspirin_sf2_file.js",
        name: "_tone_0230_Aspirin_sf2_file"
    },
    "bass, acoustic": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0320_GeneralUserGS_sf2_file.js",
        name: "_tone_0320_GeneralUserGS_sf2_file"
    },
    "bass, electric (finger)": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0350_JCLive_sf2_file.js",
        name: "_tone_0350_JCLive_sf2_file"
    },
    "guitar, acoustic": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0241_JCLive_sf2_file.js",
        name: "_tone_0241_JCLive_sf2_file"
    },
    "guitar, electric": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0260_JCLive_sf2_file.js",
        name: "_tone_0260_JCLive_sf2_file"
    },
    "guitar, overdrive": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0291_LesPaul_sf2_file.js",
        name: "_tone_0291_LesPaul_sf2_file"
    },
    "piano": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0020_JCLive_sf2_file.js",
        name: "_tone_0020_JCLive_sf2_file"
    },
    "organ": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0180_Chaos_sf2_file.js",
        name: "_tone_0180_Chaos_sf2_file"
    },
    "banjo": {
        path: "https://surikov.github.io/webaudiofontdata/sound/1050_FluidR3_GM_sf2_file.js",
        name: "_tone_1050_FluidR3_GM_sf2_file"
    },
    "saxophone": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0650_FluidR3_GM_sf2_file.js",
        name: "_tone_0650_FluidR3_GM_sf2_file"
    },
    "shakuhachi": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0770_SBLive_sf2.js",
        name: "_tone_0770_SBLive_sf2"
    },
    "sitar": {
        path: "https://surikov.github.io/webaudiofontdata/sound/1040_Aspirin_sf2_file.js",
        name: "_tone_1040_Aspirin_sf2_file"
    },
    "bassoon": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0700_FluidR3_GM_sf2_file.js",
        name: "_tone_0700_FluidR3_GM_sf2_file"
    },
    "bass": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0350_JCLive_sf2_file.js",
        name: "_tone_0350_JCLive_sf2_file"
    },
    "violin": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0400_JCLive_sf2_file.js",
        name: "_tone_0400_JCLive_sf2_file"
    },
    "cello": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0420_JCLive_sf2_file.js",
        name: "_tone_0420_JCLive_sf2_file"
    },
    "clarinet": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0710_Chaos_sf2_file.js",
        name: "_tone_0710_Chaos_sf2_file"
    },
    "flute": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0730_JCLive_sf2_file.js",
        name: "_tone_0730_JCLive_sf2_file"
    },
    "french horn": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0600_GeneralUserGS_sf2_file.js",
        name: "_tone_0600_GeneralUserGS_sf2_file"
    },
    "harp": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0460_GeneralUserGS_sf2_file.js",
        name: "_tone_0460_GeneralUserGS_sf2_file"
    },
    "koto": {
        path: "https://surikov.github.io/webaudiofontdata/sound/1070_FluidR3_GM_sf2_file.js",
        name: "_tone_1070_FluidR3_GM_sf2_file"
    },
    "marimba": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0121_FluidR3_GM_sf2_file.js",
        name: "_tone_0121_FluidR3_GM_sf2_file"
    },
    "music box": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0100_SBLive_sf2.js",
        name: "_tone_0100_SBLive_sf2"
    },
    "oboe": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0680_JCLive_sf2_file.js",
        name: "_tone_0680_JCLive_sf2_file"
    },
    "trumpet": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0560_GeneralUserGS_sf2_file.js",
        name: "_tone_0560_GeneralUserGS_sf2_file"
    },
    "tuba": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0580_GeneralUserGS_sf2_file.js",
        name: "_tone_0580_GeneralUserGS_sf2_file"
    },
    "vibraphone": {
        path: "https://surikov.github.io/webaudiofontdata/sound/0110_GeneralUserGS_sf2_file.js",
        name: "_tone_0110_GeneralUserGS_sf2_file"
    },

    // drums

    "cabasa": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12869_6_JCLive_sf2_file.js",
        name: "_drum_69_6_JCLive_sf2_file"
    },
    "snare drum": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12840_6_JCLive_sf2_file.js",
        name: "_drum_40_6_JCLive_sf2_file"
    },
    "bass drum": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12835_21_FluidR3_GM_sf2_file.js",
        name: "_drum_35_21_FluidR3_GM_sf2_file"
    },
    "closed hi-hat": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12842_0_FluidR3_GM_sf2_file.js",
        name: "_drum_42_0_FluidR3_GM_sf2_file"
    },
    "open hi-hat": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12846_0_FluidR3_GM_sf2_file.js",
        name: "_drum_46_0_FluidR3_GM_sf2_file"
    },
    "mid tom": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12847_21_FluidR3_GM_sf2_file.js",
        name: "_drum_47_21_FluidR3_GM_sf2_file"
    },
    "high tom": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12848_21_FluidR3_GM_sf2_file.js",
        name: "_drum_48_21_FluidR3_GM_sf2_file"
    },
    "crash cymbal": {
        path: "https://surikov.github.io/webaudiofontdata/sound/12849_21_FluidR3_GM_sf2_file.js",
        name: "_drum_49_21_FluidR3_GM_sf2_file"
    },
}

export default instrumentData