require("./GuideFrame.css")

export default class GuideFrame {
    visible = false;
    mode = "";
    id;

    visibleClassName = "guideFrameVisible";
    hiddenClassName = "guideFrameHidden";

    headerClassName = "guideFrameHeader";
    titleTextClassName = "guideFrameTitleText";
    closeButtonClassName = "guideFrameCloseButton";

    bodyClassName = "guideFrameBody";
    backgroundClassName = "guideBackground";

    bodyTextModeClassname = "guideFrameBodyTextMode"

    videoFrameClassname = "guideVideoIframe"

    frame;

    constructor(parent, id) {
        console.log("Creating GuideFrame");
        this.parent = parent;
        this.id = id;

        // build GuideFrame
        this.frame = document.createElement("div");
        this.frame.className = this.hiddenClassName;
        this.frame.id = "GuideFrame" + id;

        this.header = document.createElement("div");
        this.header.className = this.headerClassName;
        this.header.id = this.frame.id + "Header";
        this.frame.append(this.header);

        this.titleText = document.createElement("p");
        this.titleText.className = this.titleTextClassName;
        this.titleText.id = this.frame.id + "TitleText";
        this.header.append(this.titleText);

        this.closeButton = document.createElement("button");
        this.closeButton.className = this.closeButtonClassName;
        this.closeButton.id = this.frame.id + "CloseButton";
        this.closeButton.innerText = "X";
        this.closeButton.onclick = this.hide;
        this.header.append(this.closeButton);

        this.body = document.createElement("div");
        this.body.className = this.bodyClassName;
        this.body.id = this.frame.id + "Body";
        this.frame.append(this.body);

        // add a background
        this.background = document.createElement("div");
        this.background.className = this.backgroundClassName;
        this.background.id = this.frame.id + "Background";
        this.background.append(this.frame);

        // make GuideFrame draggable
        this.makeDraggable(this.frame, this.header);

        // insert GuideFrame
        this.parent.append(this.background);
    }

    setData = (data) => {
        const type = data.type;
        if (!type) return;
        this.setMode(type.toLowerCase());
        const title = data.title || "Guide Block";
        this.setTitle(title);
        const value = data.value || "";
        this.setValue(value);
    }

    setMode = (mode) => {
        if (!this.frame) return;
        if (mode === this.mode) return;

        // clear previous mode stuff
        this.body.innerHTML = "";

        this.mode = mode;
        switch(mode) {

            case "text":
                // build text scrollview;
                this.bodyTextMode = document.createElement("p");
                this.bodyTextMode.className = this.bodyTextModeClassname;
                this.bodyTextMode.id = "GuideBlockBodyTextMode" + this.id;
                this.body.append(this.bodyTextMode);
                break;
            case "video":
                // build video frame;
                this.videoFrame = document.createElement("iframe");
                this.videoFrame.className = this.videoFrameClassname;
                this.videoFrame.id = "GuideBlockVideoFrame" + this.id;
                this.videoFrame.setAttribute("frameborder", "0");
                this.videoFrame.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
                this.videoFrame.setAttribute("allowfullscreen", "true");
                this.body.append(this.videoFrame);
                /*
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Y82jDHRrswc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                 */

        }
    }

    setTitle = (title) => {
        if (!this.frame) return;

        this.titleText.innerText = title;
    }

    setValue = (value) => {
        if (!this.frame) return;

        switch(this.mode) {
            case "text":
                if (!this.bodyTextMode) return;
                this.bodyTextMode.innerText = value;
                break;
            case "video":
                if (!this.videoFrame) return;
                if (value.includes("https://www.youtube.com")) {
                    const link = this.getYoutubeEmbedLink(value);
                    if (link !== null) {
                        this.videoFrame.setAttribute("src", link);
                    } else {
                        this.videoFrame.innerText = "Error: failed to create YouTube embed URL";
                    }
                } else {
                    this.videoFrame.innerText = "Error: Invalid YouTube URL";
                }
                break;
        }
    }

    /**
     * getYoutubeEmbedLink
     * @param youtubeLink - in the form of https://www.youtube.com/watch?v=Y82jDHRrswc
     * @return string - in the form of https://www.youtube.com/embed/Y82jDHRrswc
     */
    getYoutubeEmbedLink = (youtubeLink) => {
        const splitResults = youtubeLink.split("?v=");
        if (splitResults.length > 1) {
            const videoID = splitResults[1];
            return "https://www.youtube.com/embed/" + videoID;
        }
        return null;
    }

    show = () => {
        if (!this.frame) return;
        this.frame.className = this.visibleClassName;
    }

    hide = () => {
        if (!this.frame) return;
        this.frame.className = this.hiddenClassName;
    }

    makeDraggable = (element, header) => {
        const background = this.background // document

        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        header.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;

            background.style.pointerEvents = "auto";
            background.onmouseup = closeDragElement;
            background.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            element.style.top = (element.offsetTop - pos2) + "px";
            element.style.left = (element.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            background.onmouseup = null;
            background.onmousemove = null;
            background.style.pointerEvents = "none";
        }
    }

    moveTo = (leftPercent, topPercent) => {
        this.frame.style.left = leftPercent + "%";
        this.frame.style.top = topPercent + "%";
    }
}
